'use strict';

var selectors = {
    mapComponent: 'svg.map',
    mapItem: 'path',
    priceContainer: '.js-price-avarage',
    priceValue: '.js-price-val',
    priceTrend: '.js-price-trend',
    locationName: '.js-location-name',
};

var regionalAvgPrices = [];

var mainTrend = $(selectors.priceTrend).data('trend') || '';

module.exports = {
    /**
     * Handles click event of the individual map item on map.
     */
    selectArea: function () {
        $(selectors.mapComponent).on('click', selectors.mapItem, function (e) {
            e.preventDefault();
            // Do nothing for Department map element
            if ($(this).closest(selectors.mapComponent).hasClass('departement')) {
                return;
            }

            var areaId = $(this).attr('id') || '';
            var $mapContainer = $(this).closest('.map-container');
            var pricePageUrl = new URL($mapContainer.data('url'));
            var pageType = $mapContainer.data('page-type');
            var regionId = $mapContainer.data('region-id');

            if (!pageType) {
                pricePageUrl.searchParams.set('pt', 'rg');
                pricePageUrl.searchParams.set('rg', areaId);
            } else if (pageType === 'rg' || pageType === 'dt') {
                pricePageUrl.searchParams.set('pt', 'dt');
                pricePageUrl.searchParams.set('rg', regionId);
                pricePageUrl.searchParams.set('dt', areaId);
            }
            window.location.href = pricePageUrl.toString();
        });
    },
    /**
     * Handles mouseover event for individual map item on Map.
     */
    hoverArea: function () {
        $(selectors.mapComponent).on('mouseover', selectors.mapItem, function (e) {
            e.preventDefault();
            // Do nothing for Department map element
            if ($(this).closest(selectors.mapComponent).hasClass('departement')) {
                return;
            }
            var areaId = $(this).attr('id') || '';
            regionalAvgPrices = JSON.parse($('#regionalAvgPrices').val());
            var currentRegionData = regionalAvgPrices ? regionalAvgPrices.find(function (rg) {
                return rg.code === areaId;
            }) : {};

            // Show corresponding area values on the left
            var avgPrice = currentRegionData && !Number.isNaN(+currentRegionData.avg) ? currentRegionData.avg.toFixed(2).replace('.',',') || '-' : '-';
            var name = currentRegionData ? currentRegionData.name || '-' : '-';
            var trend = currentRegionData ? currentRegionData.trend : '';

            $(selectors.locationName).text(name);
            $(selectors.priceValue).text(avgPrice);
            $(selectors.priceTrend).attr('data-trend', trend || '');
            $(this).attr('fill', '#706F6F');
            $(this).siblings('path').attr('fill', '#D6D5D4');
        });

        $(selectors.mapComponent).on('mouseleave', selectors.mapItem, function () {
            var mainLocation = $(selectors.locationName).data('name') || '-';
            var mainPrice = $(selectors.priceContainer).data('price') || '-';
            $(selectors.locationName).text(mainLocation);
            $(selectors.priceValue).text(mainPrice);
            $(selectors.priceTrend).attr('data-trend', mainTrend);
            $(this).attr('fill', '#D6D5D4');
        });
    }
}
